import "select2";
import "select2/dist/css/select2.css";
import "../../../stylesheets/accounts/base.scss";
import "intl-tel-input/build/css/intlTelInput.css";
import QRCodeStyling from "qr-code-styling";
import html2canvas from "html2canvas";

document.addEventListener("DOMContentLoaded", () => {
  $(".qr-download").on("click", function (e) {
    var image_url =
      this.dataset.imageUrl ||
      "https://storage.googleapis.com/fundingplatform-files/assets/donando/logo-donando-black.png";
    var url = this.dataset.url;
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    } else if (/^http:\/\//i.test(url)) {
      url = url.replace(/^http:\/\//i, "https://");
    }
    var name = this.dataset.name;
    var qrcode = new QRCodeStyling({
      text: `${url}`,
      width: 500,
      height: 500,
      colorDark: "#000000",
      colorLight: "#ffffff",
      data: url,
      image: image_url,
      type: "png",
      backgroundOptions: {
        color: "#e9ebee",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10,
      },
    });
    qrcode.download({ name: name, extension: "png" });
  });

  if ($("#money-box-download").length) {
    var image_url = $("#money-box-download")[0].dataset.imageUrl;
    var url = $("#money-box-download")[0].dataset.url;
    var campaignImg = $("#money-box-download")[0].dataset.campaignImg;
    var qrcode = new QRCodeStyling({
      text: `${url}`,
      width: 275,
      height: 275,
      colorDark: "#000000",
      colorLight: "#ffffff",
      data: url,
      image: image_url,
      type: "png",
      backgroundOptions: {
        color: "#ffffff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10,
      },
    });
    qrcode.append(document.getElementById("qrCanvas"));

    let img = new Image();
    img.src = campaignImg;
    img.setAttribute("crossorigin", "anonymous");
    img.addEventListener("load", function () {
      let canvas = document.getElementById("campaignImgCanvas");
      let ctx = canvas.getContext("2d");
      drawImageScaled(img, ctx);
    });
  }
  $("#money-box-download").on("click", function () {
    var name = this.dataset.name;

    html2canvas(document.getElementById("money-box")).then((canvas) => {
      let link = document.createElement("a");
      link.download = name;
      link.href = canvas.toDataURL("image/png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  });

  function drawImageScaled(img, ctx) {
    var canvas = ctx.canvas;
    var hRatio = canvas.width / img.width;
    var vRatio = canvas.height / img.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (canvas.width - img.width * ratio) / 2;
    var centerShift_y = (canvas.height - img.height * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShift_x,
      centerShift_y,
      img.width * ratio,
      img.height * ratio
    );
  }
});
